import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  stroke: "currentColor",
  d: "M19.5 9L12.5 16L5.5 9",
  "stroke-width": "2",
  "stroke-linecap": "round",
  "stroke-linejoin": "round"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("path", _hoisted_1))
}